<template>
    <v-container fluid>
      <base-material-card
      icon="mdi-clipboard-text"
      title="3. ระยะเวลาปฏิบัติการปฏิบัติหน้าที่สอนในสถานศึกษาปัจจุบัน"
      class="px-5 py-3"
    >
      <v-row>
        <v-col cols="12">
          <v-card :loading="loading">
        <template slot="progress">
          <v-progress-linear
            color="deep-purple"
            height="10"
            indeterminate
          ></v-progress-linear>
        </template>

        <v-card-text>
          <v-row align="center" class="mx-0">    
  
            <div class="grey--text ms-4">
         ตำแหน่ง : {{ user_status_type }}    
          </div>
          </v-row>
  
          <div class="my-4">
           วันที่บรรจุ : {{  dmy_app_cal  }} <br>   
           อายุราชการ : {{ get_gov_Age }} 
          
          </div>
          <h2>        วันที่ ปฏิบัติงาน ณ สถานศึกษา ปัจจุบัน : {{  users.date_app_now | moment("add", "543 years")
                      | moment("D MMMM YYYY")  }}
              <br>
              อายุราชการ ณ สถานศึกษาปัจจุบัน : {{ get_Appoint_Age }}  </h2>
        
       
        </v-card-text>
  
        <v-divider class="mx-4"></v-divider>  
      </v-card>
       
        </v-col>
       
      </v-row>
    </base-material-card>

  
    </v-container>
  </template>
  
  <script>
  export default {
    name: "HRvecServiceAge",
  
    data() {
      return {
        ApiKey: "HRvec2021",
        users: [],
        loading: false,
        selection: 1
      };
    },
  
    async mounted() {
      let result;
      this.loading = true;
      let useression = JSON.parse(sessionStorage.getItem("user")) || 0;
      result = await this.$http
        .post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          id_card: useression.id_card
        })
        .finally(() => (this.loading = false));
      this.users = result.data;
      
    },
  
    methods: {
      reserve() {
        this.loading = true;
  
        setTimeout(() => (this.loading = false), 2000);
      }
    },
    computed: {  
      time_stamp() {
        const d = new Date();
        let time = Math.floor(d.getTime() / 1000);
        return time;
      },
      user_status_type() {
        let user_status = this.users.user_status;
        let result;
        if (user_status == "teacher") {
          result = "ข้าราชการครู";
        } else if (user_status == "director") {
          result = "ผู้อำนวยการวิทยาลัย";
        } else if (user_status == "se_director") {
          result = "รองผู้อำนวยการวิทยาลัย";
        }
        return result;
      },
      dmy_app_cal() {
        let month = this.users.appoin_month;
        let day = this.users.appoin_day;
        let year = this.users.appoin_year - 543;
        let result = month + "/" + day + "/" + year;
        return result;
      },
      month_now() {
        const d = new Date();
        let month = d.getMonth() + 1;
        return month;
      },  
  
      cal_age_gov() {
        const today = new Date();
        const birthDate = new Date(this.dmy_app_cal);
        const yearsDifference = today.getFullYear() - birthDate.getFullYear();
        if (
          today.getMonth() < birthDate.getMonth() ||
          (today.getMonth() === birthDate.getMonth() &&
            today.getDate() < birthDate.getDate())
        ) {
          return yearsDifference - 1;
        }
        return yearsDifference;
      },
   
      get_Appoint_Age() {
        let today = new Date();
        let DOB = new Date(this.users.date_app_now);
  
        let totalMonths =
          (today.getFullYear() - DOB.getFullYear()) * 12 +
          today.getMonth() -
          DOB.getMonth();
        totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
        let years = today.getFullYear() - DOB.getFullYear();
        if (DOB.getMonth() > today.getMonth()) years = years - 1;
        else if (DOB.getMonth() === today.getMonth())
          if (DOB.getDate() > today.getDate()) years = years - 1;
  
        let days;
        let months;
  
        if (DOB.getDate() > today.getDate()) {
          months = totalMonths % 12;
          if (months == 0) months = 11;
          let x = today.getMonth();
          switch (x) {
            case 1:
            case 3:
            case 5:
            case 7:
            case 8:
            case 10:
            case 12: {
              let a = DOB.getDate() - today.getDate();
              days = 31 - a;
              break;
            }
            default: {
              let a = DOB.getDate() - today.getDate();
              days = 30 - a;
              break;
            }
          }
        } else {
          days = today.getDate() - DOB.getDate();
          if (DOB.getMonth() === today.getMonth()) months = totalMonths % 12;
          else months = totalMonths % 12;
        }
        let age = years + " ปี " + months + " เดือน " + days + " วัน";
        return age;
      },
  
      get_gov_Age() {
        let today = new Date();
        let DOB = new Date(
          parseInt(this.users.appoin_year) -
            543 +
            "-" +
            this.users.appoin_month +
            "-" +
            this.users.appoin_day
        );
        let totalMonths =
          (today.getFullYear() - DOB.getFullYear()) * 12 +
          today.getMonth() -
          DOB.getMonth();
        totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
        let years = today.getFullYear() - DOB.getFullYear();
        if (DOB.getMonth() > today.getMonth()) years = years - 1;
        else if (DOB.getMonth() === today.getMonth())
          if (DOB.getDate() > today.getDate()) years = years - 1;
  
        let days;
        let months;
  
        if (DOB.getDate() > today.getDate()) {
          months = totalMonths % 12;
          if (months == 0) months = 11;
          let x = today.getMonth();
          switch (x) {
            case 1:
            case 3:
            case 5:
            case 7:
            case 8:
            case 10:
            case 12: {
              let a = DOB.getDate() - today.getDate();
              days = 31 - a;
              break;
            }
            default: {
              let a = DOB.getDate() - today.getDate();
              days = 30 - a;
              break;
            }
          }
        } else {
          days = today.getDate() - DOB.getDate();
          if (DOB.getMonth() === today.getMonth()) months = totalMonths % 12;
          else months = totalMonths % 12;
        }
        let age = years + " ปี " + months + " เดือน " + days + " วัน";
        return age;
      },
  
     
  
    
  
     
    }
  };
  </script>
  
  <style lang="scss" scoped></style>
  